.FollowersCard{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 0.7rem;
    width: 100%;
    font-size: 13px;
}
.followers{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.followers>div:nth-of-type(1){
    display: flex;
    gap: 1rem;
    align-items: center;
}
.followerImg{
    height: 3.2rem;
    width: 3.2rem;
    border-radius: 50%;
    object-fit: cover;
}
.name{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.name>span:nth-of-type(1){
    font-weight: bold;
}
.fc-button{
    height: 2rem;
    padding-left: 20PX;
    padding-right: 20PX;
}
