.Trends{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: var(--cardColor);
    padding: 1rem 2rem;
    border-radius: 1rem;
}
.Trend{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.Trend>span:nth-of-type(1){
    font-weight: bold;
}
.Trend>span:nth-of-type(2){
    font-weight: normal;
    font-size: 13px;
}