.Navbar{
    display: grid;
    grid-template-columns: 20rem auto 20rem;
    gap: 1rem;
}
.Link-logo-d{
    text-decoration: none;
}
.Link-logo-d>div{
    display: flex;
    gap: 1rem;
}
.ProfileNavImg{
    width: 3rem;
    height: 3rem;
}
.Link-logo-d>div>h3{
    margin: 0;
    font-size: 30px;
    color: var(--blue);
}
.Navbar>div:nth-of-type(2){
    width: 100%;
    display: flex;
    justify-content: center;
}
.Search{
    display: flex;
    background-color: var(--inputColor);
    border-radius: 10px;
    padding: 5px;
    width: 95%;
}
.Search>input{
    background-color: transparent;
    border: none;
    outline: none;
    width: 95%;
}
.s-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--blue);
    border-radius: 5px;
    padding: 4px;
    color: white;
}
.s-icon:hover{
    cursor: pointer;
}


                    /* Mobile-view */

.Navbar-m{
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 5px 10px;
    justify-content: space-between;
    background-color: white;
}
.NavbarImg-m{
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.Navbar-notification{
    width: 2rem;
    height: 2rem;
}
.Search-m{
    display: flex;
    background-color: var(--inputColor);
    border-radius: 5px;
    padding: 5px;
    width: 95%;
}
.Search-m>input{
    padding-top: 2px;
    padding-bottom: 2px;
}
