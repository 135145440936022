.FollowersModal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  top: 10rem;
  left: 25rem;
  background-color: white;
  border-radius: 1rem;
  padding: 1rem;
  width: 40rem;
}
.FollowersModal>h3{
    align-self: center;
    font-size: 1.5rem;
    margin: 0;
    color: var(--blue);
}
.morefollowers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.morefollowers > div:nth-child(1) {
  display: flex;
  gap: 1rem;
}
