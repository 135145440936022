.ProfileCenter{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.ProfileCenterHeader{
    display: flex;
    flex-direction: column;
    background-color: var(--cardColor);
    gap: 1rem;
    padding: 1rem;
    padding-bottom: 3rem;
    border-radius: 1rem;
}
.ProfileCenterHeader>div:nth-of-type(1){
    position: relative;
}
.ProfileCenterHeader>div:nth-of-type(1)>img{
    object-fit: cover;
    width: 100%;
    height: 20rem;
    border-radius: 1rem;
}
.button-cover{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.571);
    color: white;
    bottom: 15px;
    right: 15px;
    border-radius: 0;
    padding: 5px 10px;
}
.button-cover:hover{
    border: 2px solid rgba(0, 0, 0, 0.571);
    color: white;
    font-weight: bold;
}
.Profile-name{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: absolute;
    bottom: 10px;
    left: 120px;
}
.Profile-name>h3{
    margin: 0;
    font-size: 28px;
}
.Profile-name>span{
    font-size: 12px;
    font-weight: bold;
}
.ProfileCenterHeader>div:nth-of-type(2){
    position: relative;
}
.ProfileImgCenter{
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
    left: 20px;
    bottom: -10px;
}
.ProfileImgHeadings{
    display: flex;
    gap: 2rem;
    position: absolute;
    left: 120px;
    font-size: 16px;
}
.ProfileImgHeadings:nth-child(1){
    font-weight: bold;
}

.ProfileCenter-m{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.ProfileCover-m{
    position: relative;
}
.ProfileCover-m>img{
    height: 10rem;
    object-fit: cover;
    position: relative;
}
.ProfileImage-m{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0rem 1rem;
    position: absolute;
    width: -webkit-fill-available;
    top: 8rem;
}
.ProfileImage-m>img{
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
}
.ProfileImage-m>span{
    border: 2px solid gray;
    border-radius: 10px;
    width: fit-content;
    height: fit-content;
    font-size: 10px;
    padding: 5px 10px;
}
.ProfileName-m{
    padding: 1rem;
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
}
.ProfileName-m>span:nth-of-type(1){
    font-weight: bold;
}
.ProfileName-m>span:nth-of-type(2){
    font-size: 10px;
}
.ProfileCenter-m>div:nth-of-type(4) {
    display: flex;
    flex-direction: column;
}
.ProfileCenter-m>div:nth-of-type(4)>div{
    display: flex;
    justify-content: space-between;
    padding: 0rem 1rem;
}
