.Posts{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.Post{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 1rem;
    background-color: var(--cardColor);
    gap: 1rem;
}
.Post>img{
    width: 100%;
    max-height: 20rem;
    object-fit: cover;
    border-radius: 0.5rem;
}
.postReact{
    display: flex;
    gap: 1rem;
    align-items: flex-start;
}
.Post>div:nth-of-type(2){
    font-size: 12px;
}
.detail{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.detail>span:nth-of-type(1){
    font-weight: bold;
    font-size: 15px;
}
.detail>span:nth-of-type(2){
    font-size: 12px;
}


.Posts-m{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.Post-m{
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: var(--cardColor);
    padding: 0;
    margin: 0;
}
.Post-m>:nth-child(1){
    width: 100%;
    padding: 0;
    margin: 0;
}
.postReact-m{
    display: flex;
    gap: 10px;
    width: 20px;
    height: 20px;
}
.Post-m>div:nth-of-type(2){
    font-size: 12px;
}
.detail-m{
    display: flex;
    gap: 10px;
    align-items: center;
}
.detail-m>span:nth-of-type(1){
    font-weight: 500;
    font-size: 14px;
}
.detail-m>span:nth-of-type(2){
    font-size: 10px;
}