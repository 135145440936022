.ChatLeft{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: var(--cardColor);
    border-radius: 1rem;
    padding: 1rem;
}
.ChatLeft>h3{
    margin: 0;
    font-size: 1.5rem;
    padding-bottom: 2rem;
}
.Chat-l{
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1rem;
    font-weight: bold;
}
.Chat-l>div:nth-of-type(1)>img{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    object-fit: cover;
}
.Chat-l:nth-of-type(1){
    background-color: gray;
    padding: 1rem;
    border-radius: 1rem;
}
.Chat-l:hover{
    background-color: gray;
    padding: 1rem;
    border-radius: 1rem;
    transform: scale(1.1);
}