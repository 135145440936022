.ChatRight {
  background-color: var(--cardColor);
  position: relative;
  height: 84vh;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.ChatRight>div:nth-of-type(1){
    background: gray;
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    gap: 1rem;
    width: 97%;
    align-items: center;
}
.ChatRight > div:nth-of-type(1)>span > h4{
    margin: 0;
    font-size: 2rem;
}
.ChatRight > div:nth-of-type(2) {
  display: flex;
  align-items: center;
  background-color: var(--inputColor);
  justify-content: center;
  height: 3rem;
  position: absolute;
  bottom: 1rem;
  border-radius: 1rem;
  width: 90%;
}
.ChatRight > div:nth-of-type(2)>input{
    background-color: transparent;
    width: 100%;
}
.Chatimg {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
}
.button-send{
    margin-right: 20px;
    padding: 10px 20px;
    height: 90%;
}
