.Chat-d{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100vh;
}
.Chat-body-d{
    display: grid;
    grid-template-columns: 20rem auto;
    gap: 1rem;
}