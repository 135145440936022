.ProfileLeft{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 1;
    position: relative;
}
.ProfileInfo{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--cardColor);
    border-radius: 1rem;
    padding: 2rem;
}
.ProfileInfo>div:nth-of-type(1){
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ProfileInfo>div:nth-of-type(1)>h3{
    margin: 0;
}
.ProfileInfo>div:nth-of-type(1)>span>svg{
    width: 1rem;
    height: 1rem;
}
.ProfileInfo>div:nth-of-type(2){
    display: flex;
    gap: 10px;
}
.ProfileInfo>div:nth-of-type(2)>span:nth-of-type(1){
    font-weight: bold;
}
.ProfileInfo>div:nth-of-type(3){
    display: flex;
    gap: 10px;
}
.ProfileInfo>div:nth-of-type(3)>span:nth-of-type(1){
    font-weight: bold;
}
.ProfileInfo>div:nth-of-type(4){
    display: flex;
    gap: 10px;
}
.ProfileInfo>div:nth-of-type(4)>span:nth-of-type(1){
    font-weight: bold;
}
.Logout-b{
    margin-top: 40px;
    align-self: flex-end;
    padding: 8px 20px;
}
.suggestions{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.suggestions>div:nth-child(1){
    display: flex;
    gap: 1rem;
}
.suggestions>div:nth-child(1)>img{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
}
.suggestions-name{
    display: flex;
    flex-direction: column;
}
.ProfileLeft>span{
    align-self: center;
    color: var(--blue);
    font-weight: bold;
    cursor: pointer;
}