.Notification{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 18rem;
    height: 80%;
    position: absolute;
    right: 0;
    top: 5rem;
    background-color: white;
    border-radius: 1rem;
    margin-right: 1rem;
}
.Notification>div:nth-of-type(1)>h3{
    margin: 0;
    color: var(--blue);
    margin-bottom: 1rem;
}
.N-sections{
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
}
.Notification>hr{
    width: 100%;
    margin-top: 0;
}