.Setting{
    display: flex;
}
.s-left{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 25%;
    font-size: 1rem;
    font-weight: normal;
    padding: 1rem;
}
.s-left>div{
    padding-top: 1rem;
}
.s-left>h3{
    font-size: 2rem;
    margin: 0;
}
.s-left>div:nth-of-type(1)>input{
    background-color: white;
    border-radius: 1rem;
    padding-top: 1rem;

}
.s-right{
    width: 75%;
}